import React from "react"

// Libraries
import { Link } from "gatsby"

// Components
import Seo from "components/seo"

// Page Sections
import Hero from "./../components/hero"
import Recognition from "./screens/awards/recognition"

const Awards = () => {
  return (
    <React.Fragment>
      <Hero pageTitle="Awards &amp; Recognition">
        <p>
          The honors we receive help power&nbsp;us&nbsp;forward.
          <br />
          We’re truly humbled by the recognition.
          <br />
          <br />
          Can you help us push further?  <Link to="/careers/">Careers</Link>
        </p>
      </Hero>
      <Recognition />
    </React.Fragment>
  )
}

export default Awards

export const Head = () => (
  <Seo
    title="Awards &amp; Recognition"
    description="The honors we receive help power us forward. We're truly humbled by the recognition."
    image="/meta/awards.jpg"
  />
)